
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
        },
    })

    // *****************************************************************************************************************
    export default class MiscHierarchyOfNeeds extends Vue {
        // Methods -----------------------------------------------------------------------------------------------------
        private data() {
            return {
                aestheticImagePathAndName:
                    require("@/assets/images/aesthetic.png"),

                aesthetic2ImagePathAndName:
                    require("@/assets/images/aesthetic_2.png"),

                actualization1ImagePathAndName:
                    require("@/assets/images/actualization_1.png"),

                actualization2ImagePathAndName:
                    require("@/assets/images/actualization_2.png"),

                aliceCooperImagePathAndName:
                    require("@/assets/images/alice_cooper.png"),

                apartmentImagePathAndName:
                    require("@/assets/images/apartment.png"),

                curiosityImagePathAndName:
                    require("@/assets/images/curiosity.png"),

                cognitiveImagePathAndName:
                    require("@/assets/images/cognitive.png"),

                friendshipsPathAndName:
                    require("@/assets/images/friendships.png"),

                needsIconPathAndName:
                    require("@/assets/images/icon_needs.png"),

                maslowImagePathAndName:
                    require("@/assets/images/abraham_maslow.png"),

                selfEsteemImagePathAndName:
                    require("@/assets/images/self_esteem.png"),

                transcendence1ImagePathAndName:
                    require("@/assets/images/transcendence_1.png"),

                transcendence2ImagePathAndName:
                    require("@/assets/images/transcendence_2.png"),
            };
        }
    }
